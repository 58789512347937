@import "../../../styles/variables";
.TopProductModule {
  .topProduct {
    text-decoration: none;
    text-transform: uppercase;
    h2 {
      font-size: 1.7rem;
      color: rgb(63, 63, 63);
      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }
  }
  .xemThem {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgb(92, 92, 92);
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
    svg {
      fill: white;
      margin-left: 5px;
      background-color: $primary;
      border-radius: 7px;
      width: 24px;
      height: 24px;
      padding: 5px;
    }
  }
  .listProducts {
    display: flex;
    flex-wrap: wrap;
    .productRowBox {
      display: flex;
      border: 1px solid #e6e6e6;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 20px;
      .itemImgLink img {
        width: 150px;
        border-radius: 10px;
        margin-right: 10px;
        height: 103px;
        position: relative;
      }
      .ProductContent {
        position: relative;
        .itemNameLink {
          text-decoration: none;
          font-weight: 700;
          font-size: 15px;
          color: $black;
          &:hover {
            color: $primary;
            text-decoration: underline;
          }
        }
        .blockPrices {
          position: absolute;
          bottom: 5px;
          .card_price {
            color: $secondary;
            font-weight: 800;
            font-size: 1rem;
            margin-right: 0.8rem;
          }

          .card_price_origin {
            font-weight: light;
            color: gray;
            font-size: 1rem;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 994px) {
  .TopProductModule {
    padding: 0 !important;
    .topProduct {
      h2 {
        font-size: 1.4rem;
      }
    }
  }
  .productRowBox {
    display: block !important;
    .itemImgLink img {
      width: 100% !important;
      height: 150px !important;
      padding-bottom: 0.5rem;
    }
    .ProductContent {
      .blockPrices {
        position: initial !important;
        padding-top: 30px;
        padding-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 554px) {
  .productRowBox {
    .ProductContent {
      .itemNameLink {
        font-size: 0.8rem !important;
      }
    }
  }
  .ProductContent {
    .blockPrices {
      .card_price,
      .card_price_origin {
        font-size: 0.9rem !important;
      }
    }
  }
}
