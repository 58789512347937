@import "../../../styles/variables";
.CheckoutInformationContainer {
  display: flex;
  justify-content: space-between;
  .left,
  .right {
    width: 100%;
    padding: 0 20px;
  }
  .left {
    .CheckoutButton {
      margin-top: 0.5rem;
      width: 100%;
      p {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
  .invalid {
    color: red;
    font-size: 13px;
    margin-top: 10px;
  }
  .right {
    .itemModifiers {
      .modifiers_item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 13.5px;
        padding-left: 1rem;
        div {
          padding-left: 4px;
        }
      }
    }
    .itemRow {
      float: left;
      width: 100%;
      padding-bottom: 10px;
      margin-bottom: 1rem;
      border-bottom: solid 1px #ebebeb;
      .itemThumb {
        float: left;
        max-width: 100%;
        img {
          height: 70px;
          width: 70px;
          margin: 0;
        }
      }
      .itemName {
        width: 100%;
        text-align: left;
        padding-left: 85px;
        padding-bottom: 5px;
        .cartOrderItemName {
          text-decoration: none;
          color: #000;
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 0.5rem;
          &:hover {
            color: $primary;
            text-decoration: underline;
          }
        }
      }
      .itemPrice {
        margin-left: 1rem;
        margin-right: 1rem;
        float: left;
        color: $secondary;
        font-weight: 700;
      }
      .itemDecrement {
        cursor: pointer;
        background: transparent;
        float: left;
        border: 1px solid;
        color: $primary;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        padding: 0;
      }
      .itemQuantity {
        color: #222;
        float: left;
        font-weight: 500;
        margin-left: 8px;
        margin-right: 8px;
        background-color: #fff;
        text-align: center;
      }
      .itemIncrement {
        cursor: pointer;
        background: transparent;
        border: 1px solid;
        color: $primary;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        box-shadow: none;
        padding: 0;
      }

      .itemRemove {
        cursor: pointer;
        float: right;
        color: $secondary;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .ListProducts {
      p {
        span {
          font-weight: bold;
        }
      }
    }
  }
}

@media screen and (max-width: 996px) {
  .CheckoutInformationContainer {
    flex-wrap: wrap;
  }
  .right {
    margin-top: 3rem;
  }
}
