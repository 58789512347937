@import "../../../styles/variables";
.Navbar_Container {
  .TopBar {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left_menu {
      display: none;
    }
    .left {
      width: 28%;
      p {
        font-size: 13px;
      }
    }
    .center {
      width: 10%;
      img {
        width: auto;
        height: 60px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .Hotline {
        display: flex;
        align-items: center;
        .HotLine_Info {
          margin-left: 1rem;
          p {
            font-size: 14px;
            font-weight: 500;
            color: #999;
            line-height: 1.2rem;
          }
          a {
            text-decoration: none;
            margin: 0;
            font-weight: 800;
            font-size: 20px;
            color: #d50e15;
            transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
            &:hover {
              text-decoration: underline solid $primary;
              color: $primary;
            }
          }
        }
      }
      .Cart {
        cursor: pointer;
        margin-left: 1rem;
        position: relative;
        svg {
          position: relative;
          font-size: 22px;
          fill: #000;
          vertical-align: middle;
        }
        .BtnCanvas {
          background-color: none;
          small {
            position: absolute;
            top: -14px;
            right: -14px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #d50e15;
            transition: all 0.3s ease-in-out;
            text-align: center;
            line-height: 20px;
            color: #fff;
            font-size: 13px;
          }
        }
        .offcanvas {
          cursor: default;
          .orderCartItem {
            display: flex;
            flex-wrap: wrap;
            .itemModifiers {
              .modifiers_item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-size: 13.5px;
                padding-left: 1rem;
                div {
                  padding-left: 4px;
                }
              }
            }
            .itemRow {
              float: left;
              width: 100%;
              padding-bottom: 10px;
              margin-bottom: 1rem;
              border-bottom: solid 1px #ebebeb;
              .itemThumb {
                float: left;
                max-width: 100%;
                img {
                  height: 70px;
                  width: 70px;
                  margin: 0;
                }
              }
              .itemName {
                width: 100%;
                text-align: left;
                padding-left: 85px;
                padding-bottom: 5px;
                .cartOrderItemName {
                  text-decoration: none;
                  color: #000;
                  font-weight: bold;
                  font-size: 14px;
                  margin-bottom: 0.5rem;
                }
              }
              .itemPrice {
                margin-left: 1rem;
                margin-right: 1rem;
                float: left;
                color: $danger;
                font-weight: 700;
              }
              .itemDecrement {
                cursor: pointer;
                background: transparent;
                float: left;
                border: 1px solid;
                color: $primary;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                padding: 0;
              }
              .itemQuantity {
                color: #222;
                float: left;
                font-weight: 500;
                margin-left: 8px;
                margin-right: 8px;
                background-color: #fff;
                text-align: center;
              }
              .itemIncrement {
                cursor: pointer;
                background: transparent;
                border: 1px solid;
                color: $primary;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                box-shadow: none;
                padding: 0;
              }

              .itemRemove {
                cursor: pointer;
                float: right;
                color: $secondary;
                font-size: 14px;
                font-weight: bold;
              }
            }
          }
          .OrderCartItemBottom {
            position: relative;
            padding: 20px 20px 15px;
            background: #f0f0f0;
            .subTotal,
            .TotalBill {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .subTotal {
              margin-bottom: 5px;
              span {
                font-weight: bold;
              }
            }
            .TotalBill {
              span {
                font-size: 1.3rem;
                color: $secondary;
                font-weight: bold;
              }
            }
          }
          .OrderCartItemBottom::before {
            content: "";
            display: inline-block;
            height: 10px;
            margin: 0 auto;
            position: absolute;
            top: -5px;
            left: 0;
            right: 0;
            text-align: center;
            width: 10px;
            box-shadow: 0 0 0 6px #ffffff;
            background: $primary;
            border-radius: 1px;
            transform: rotate(-45deg);
          }
          .CheckoutButtonLink {
            text-decoration: none;
            color: white;
            .CheckoutButton {
              margin-top: 1rem;
              width: 100%;
              p {
                font-size: 1rem;
                font-weight: bold;
              }
            }
          }
        }
      }
      img {
        width: 45px;
        height: 45px;
      }
    }
  }
  .NavBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(45,45,159);
    background: linear-gradient(72deg, rgba(45,45,159,1) 0%, rgba(40,134,184,1) 52%);
    
    border-radius: $borderRadius;
    .Menu {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 15px 5px;
      li {
        list-style: none;
        margin-right: 10px;
        a {
          color: #fff;
          text-transform: capitalize;
          font-size: 15px;
          padding: 5px 10px;
          text-align: center;
          position: relative;
          font-weight: 800;
          line-height: 1.1;
          border-radius: 10px;
          text-decoration: none;
          transition: all 200ms ease-in-out;
          &:hover {
            color: black;
            background: white;
          }
        }
      }
    }
    .search {
      border-radius: 20px;
      padding: 5px 0.8rem;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      font-size: 15px;
      input::placeholder {
        font-size: 15px;
      }
      .search_icon {
        font-size: 20px;
        opacity: 0.6;
      }
      input {
        width: 100%;
        border: none;
        outline: none;
      }
    }
  }
}
// BURGER MENU
.NavigationBar {
  .Canvas_mobile {
    max-width: 350px;
  }
  .BtnCanvas {
    svg {
      width: 30px;
      height: 30px;
    }
  }
  li {
    list-style-type: none;
    margin-top: 0.7rem;
    a {
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: 550;
      color: $black;
      &:hover {
        color: $primary;
      }
    }
  }
}
.fadeIn {
  animation: fadeIn 1s;
  color: $primary;
  font-weight: 700;
  font-size: 12px !important;
}

@media screen and (max-width: 994px) {
  .Navbar_Container {
    position: relative;
    .left_menu {
      display: block !important;
    }
  }
  .NavBar {
    display: none !important;
  }
  .TopBar {
    .left,
    .right .Hotline {
      display: none !important;
      margin: 0 !important;
    }
    .right {
      margin-right: 1rem !important;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
