@import "../../../styles/variables";
.DetailContainer {
  width: 100%;

  .thumbnailImageMain {
    max-width: 100%;
    max-height: 450px;
    text-align: center;
    img {
      max-width: 100%;
      width: 500px;
      height: 400px;
    }
  }
  .thumbnailImageAlbum {
    text-align: center;
    p {
      margin-bottom: 1.2rem !important;
    }
  }
  .productDescription {
    h4 {
      font-size: 1.7rem;
    }
    p {
      span {
        font-weight: bold;
      }
    }
    .addToOrder {
      width: 50%;
      margin-top: 1rem;
      flex-wrap: wrap;
      button {
        width: 100%;
        padding: 0.5rem;
        flex: 1;
        &:hover {
          background: none;
          color: $primary;
        }
        p {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
  }
  .optionModifier {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
  .productPriceBox {
    box-sizing: border-box;
    margin-top: 2rem;
    h3 {
      color: $secondary;
      font-size: 2rem;
      font-weight: 800;
    }
    .productDiscountPrice {
      display: flex;
      align-items: center;
      p {
        padding-right: 3rem;
      }
      .GiaTietKiem {
        color: $success;
      }
      .GiaThiTruong {
        span {
          font-weight: 400;
          text-decoration: line-through;
        }
      }
    }
    .item_quantity::after {
      content: "";
      display: table;
      clear: both;
    }
    .item_quantity {
      margin-top: 0.5rem;
      p {
        margin: 0.5rem 0 !important;
        font-weight: bold;
      }
      button {
        float: left;
        width: 40px;
        height: 40px;
        border: 1px solid #e6e6e6;
        background-color: #fff;
        font-size: 25px;
        color: #333;
        padding: 0;
        font-weight: 700;
        line-height: 38px;
        &:hover {
          background-color: $primary;
          color: white;
        }
      }
      .btn_minus {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .btn_plus {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .custom_item_qty {
        float: left;
        width: 80px;
        height: 40px;
        border-top: #e6e6e6 solid 1px;
        border-bottom: #e6e6e6 solid 1px;
        border-radius: 0;
        color: #333f48;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        border-left: none;
        border-right: none;
      }
    }
  }
  .productInformation {
    h4 {
      font-size: 1.4rem;
      margin-top: 2rem;
    }
  }
}

.album_slide {
  width: 100%;
  height: 100px;
  position: relative;
  img {
    cursor: pointer;
    width: 140px !important;
    max-height: 80px;
    border-radius: 3px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    right: 0;
    margin: auto;
    &.active {
      border: 3px solid red;
    }
  }
}

.groupModifiersTitle {
  font-weight: 800;
  margin-bottom: .5rem;
  .modifier_group_name {
    font-size: 16px;
    margin-right: .5rem !important;
  }
  .modify_group_is_required {
    font-size: 13px;
  }
}

.SwiperSlide {
  width: 160px !important;
  margin-bottom: 1rem;
}


@media screen and (max-width: 994px) {
 
  .thumbnailImageMain {
    img {
      width: 100% !important;
    }
  }
  .addToOrder {
    width: 100% !important;
  }
}
@media screen and (max-width: 554px) {
  .productPriceBox {
    h3 {
      font-size: 1.5rem !important;
    }
  }
}