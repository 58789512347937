.NotFoundContainer {
  text-align: center;
  h2 {
    font-weight: 700;
  }
  .NotFoundButton {
    margin: auto;
    margin-top: 4rem;
    padding: 0.3rem 0.7rem;
    background: #28a745;
    border: none;
    p {
      font-weight: 600;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
}
.content_container {
  max-width: 1200px;
  margin: 60px auto;
  padding: 0 60px !important;
}

.heading {
  font-size: 50px;
  margin-bottom: 30px;
  margin: 0;
}

.description {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 40px !important;
}

.TrackingOrderContainer {
  .imageCover {
    max-width: 500px;
    img {
      width: 100%;
    }
  }
}
@media screen and (max-width: 994px) {
  .StyleCategoriesDiv,
  .StyleSingleProductDiv,
  .ContactUsContainer,
  .NotFoundContainer,
  .TrackingOrderContainer,
  .content_container {
    padding: 10px !important;
  }
  .FixContainer {
    padding: 0 !important;
  }
}
