
@import "./../../styles/variables";
.contact_list {
    list-style-type: none;
    display: flex;
    align-items: center;
    p {
        font-size: 1.1rem;
        margin-right:.5rem !important;
        font-weight: bold;
    }
    a {
        text-decoration: none;
        color: black;
        &:hover {
            color: $primary;
        }
    }
}
.contact_box {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 2rem;
    label {
        font-weight: 700;
    }
    h5 {
        font-weight: 700;
    }
}

.btn_message {
    background-color: $success;
    border: none;
    padding: .6rem 2rem;
    p {
        font-weight: 700;
    }
}

@media screen and (max-width: 994px) {
    .contact_box {
        margin-top: 1rem;
        padding: 1rem ;
    }
}

@media screen and (max-width: 554px){
    .contact_box {
        border: none !important;
    }
}