@import "../../../styles/variables";
.stickyRight {
  visibility: hidden;
  position: absolute;
  z-index: 999;
  top: "-9999px";
  left: "-9999px";
  height: 1px;
  .orderCart {
    background: #fff;
    border-radius: 4px;
    .cartMobile {
      cursor: pointer;
      display: none;
      background: $primary;
      padding: 12px;
      span {
        color: white;
        font-weight: 500;
      }
    }
    .OrderCartItemHeader {
      padding: 20px;
      overflow-y: auto;
      min-height: calc(100vh - 625px);
      max-height: calc(100vh - 300px);
      .OrderCartItem {
        display: flex;
        flex-wrap: wrap;
        
        .itemRow {
          float: left;
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 1rem;
          border-bottom: solid 1px #ebebeb;
          .itemThumb {
            float: left;
            height: 70px;
            width: 70px;
            img {
              width: 100%;
              height: 100%;
              margin: 0;
            }
          }
          .itemName {
            width: 100%;
            text-align: left;
            padding-left: 85px;
            padding-bottom: 5px;
            .cartOrderItemName {
              text-decoration: none;
              color: #000;
              font-weight: bold;
              font-size: 14px;
              margin-bottom: 0.5rem;
            }
          }
          .itemPrice {
            margin-left: 1rem;
            margin-right: 1rem;
            float: left;
            color: $danger;
            font-weight: 700;
          }
          .itemDecrement {
            cursor: pointer;
            background: transparent;
            float: left;
            border: 1px solid;
            color: $primary;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            padding: 0;
          }
          .itemQuantity {
            color: #222;
            float: left;
            font-weight: 500;
            margin-left: 8px;
            margin-right: 8px;
            background-color: #fff;
            text-align: center;
          }
          .itemIncrement {
            cursor: pointer;
            background: transparent;
            border: 1px solid;
            color: $primary;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            box-shadow: none;
            padding: 0;
          }

          .itemRemove {
            cursor: pointer;
            float: right;
            color: $secondary;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
    .OrderCartItemBottom {
      position: relative;
      padding: 20px 20px 15px;
      background: #f0f0f0;
      .subTotal,
      .TotalBill {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .subTotal {
        margin-bottom: 5px;
        span {
          font-weight: bold;
        }
      }
      .TotalBill {
        span {
          font-size: 1.3rem;
          color: $secondary;
          font-weight: bold;
        }
      }
    }
    .OrderCartItemBottom::before {
      content: "";
      display: inline-block;
      height: 10px;
      margin: 0 auto;
      position: absolute;
      top: -5px;
      left: 0;
      right: 0;
      text-align: center;
      width: 10px;
      box-shadow: 0 0 0 6px #ffffff;
      background: $primary;
      border-radius: 1px;
      transform: rotate(-45deg);
    }
  }
}

.CheckoutButtonLink {
  text-decoration: none;
  color: white;
  .CheckoutButton {
    margin-top: 1rem;
    width: 100%;
    p {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

.mobile-bottom-bar {
  position: relative;
  min-height: 100vh;
}

.bottom-bar {
  padding: 10px;
}

@media screen and (max-width: 994px) {
  .nav_link {
    font-size: 15px !important;
  }
  .stickyRight {
    visibility: visible !important;
    position: relative !important;
  }
  .cartMobile {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
}
