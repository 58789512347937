@import "../../styles/variables";

.CategoriesDetailContainer {
  h2 {
    margin-bottom: 1rem;
  }
  .CategoriesBanner {
    div {
      a {
        display: block;
        height: 209px;
        img {
          width: 100%;
          max-height: 200px;
        }
      }
    }
  }
  .CategoriesSlider {
    margin-top: 2rem;
    h2 {
      font-weight: bold;
    }
    .nav_pills_container {
      width: 100%;
      .nav_pills_slider {
        width: 100%;
        display: -webkit-inline-box;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        flex-wrap: nowrap;
        white-space: nowrap;
        border-bottom: 1px solid #e5e5e5;
        li {
          margin-bottom: 1rem;
          .nav_link {
            display: block;
            padding: 6px 26px;
            border-radius: 4px;
            margin-right: 1rem;
            border: 1px solid #e5e5e5 !important;
            font-size: 14px;
            font-weight: 700;
            text-decoration: none;
            background: 0 0;
            border: 0;
            transition: color 0.15s ease-in-out,
              background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
          }
          .nav_link.nav_link_active {
            color: white;
            background-color: $primary;
          }
        }
      }
    }

    .card {
      overflow: hidden;
      .card_title {
        display: flex;
        align-items: center;
        padding: 0;
        text-decoration: none;
        color: black;
        justify-content: left;
        height: auto;
        font-weight: 550;
        &:hover {
          color: $primary;
          text-decoration: underline;
        }
      }
      .card_price {
        color: $secondary;
        font-weight: 550;
        font-size: 1rem;
        margin-right: 0.8rem;
      }

      .card_price_origin {
        font-weight: light;
        color: gray;
        font-size: 1rem;
        text-decoration: line-through;
      }
      .card_discount {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: #c8102e;
        padding: 6px 8px;
        border-radius: 23px 23px 23px 0px;
        font-weight: 550;
        font-size: 14px;
        z-index: 3;
        color: #fff;
      }
      .zoomEffect {
        object-fit: fill;
        width: 100%;
        height: 200px;
      }
    }

    .card:hover .zoomEffect {
      transition: transform 0.5s;
      transform: scale(1.05);
    }
    .card:hover .card_title {
      color: $primary;
      text-decoration: underline;
    }
  }
  .addToCart {
    width: 100%;
    p {
      font-weight: bold;
    }
    margin-top: 1rem;
  }
}

@media screen and (max-width: 994px) {
  .CategoriesBanner {
    display: -webkit-inline-box !important;
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
    div {
      a {
        display: block;
        height: 100% !important;
        img {
          width: 100% !important;
        }
      }
    }
  }
  .card_container {
    padding: 0 4px !important;
    .card_title {
      font-size: 14px !important;
      margin-top: 1rem !important;
      height: 10px !important;
    }
    .zoomEffect {
      height: 150px !important;
    }
    .card_body {
      padding: 5px !important;
    }
  }
  .addToCart {
    margin: 5px 0;
  }
}

@media screen and (max-width: 554px) {
  .row {
    padding-left: 5px;
    padding-right: 5px;
  }
}
