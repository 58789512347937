// Color
$black: #333f48;
// $primary: #cf8b00;
$primary: #2886b8;
$secondary: #c91e03;
$success: #008f44;
$danger: #e45d15;
$info: #6c9eff;
$dark-grey: #525f7f;
// Btn-Padding
$btn-gray: #e1e1e1;
$bg-footer: #1a1a1a;

$btn-padding-x: 2rem;
$btn-padding-y: 0.475rem;

// Box shadow
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
// Border Radius
$borderRadius: 5px;

$border-color-dark: #d4dae0;
$grey-medium: #586271;
$black-light: #0b2135;
