@import "../../../styles/variables";

.topPosts {
  text-decoration: none;
  text-transform: uppercase;
  h2 {
    font-size: 1.7rem;
    color: rgb(63, 63, 63);
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}
.card {
  margin-top: 30px;
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  transform: translateY(10px);
  z-index: 2;
  height: 100% !important;
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    content: "";
    background: linear-gradient(
      to bottom,
      rgba(15, 15, 15, 0),
      rgba(15, 15, 15, 0.767) 100%
    );
    transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    cursor: pointer;
  }
  .badge {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    background-color: red;
    border-radius: inherit !important;
  }
  &:hover {
    transform: translateY(0%);
    &::after {
      opacity: 1;
      box-shadow: 0 25px 55px rgba(253, 71, 102, 0.22);
    }
    .card_thumbnail {
      transform: scale(1);
      transition: all 9s cubic-bezier(0.1, 0.2, 7, 1);
    }
    .post_slug {
      opacity: 1;
      transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1) 0.15s,
        transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1) 0.15s;
    }
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    content: "";
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), $primary 100%);
    transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    cursor: pointer;
    opacity: 0;
  }
  .card_thumbnail {
    height: 100%;
    transition: transform 1s ease, opacity 0.5s ease 0.25s;
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
  }
  .card_body {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    width: 100%;
    transform: translateX(-50%);
    transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
      transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  }
}
.mainSlug {
  height: 100%;
}
.post_slug {
  text-decoration: none;
  opacity: 1;
  position: absolute;
  bottom: 50px;
  margin-top: 10px;
  line-height: 1.5em;
  max-width: 80%;
  transition: opacity 0.18s cubic-bezier(0.05, 0.2, 0.1, 1),
    transform 0.18s cubic-bezier(0.05, 0.2, 0.1, 1);
  height: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: 0.2s;
  h5 {
    color: #fff;
    opacity: 0.7;
    font-size: 1.2rem;
  }
}
