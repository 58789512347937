.link-header {
  color: black;
  text-decoration: none;
}
.link-header:hover {
  color: gray;
}

.link-header.active {
  color: #2b6cb0;
}

.link-header.active p {
  font-size: 18px;
}

p {
  margin: 0 !important;
}

/* MAIN CONTENT OF WEBSITE */
.MainContent {
  position: relative;
  /* min-height: 100vh; */
  padding-bottom: 4rem;
}
.form-check-input {
  width: 18px !important;
  height: 18px !important;
}
.form-check-input:checked {
  background-color: #2d2d9f !important;
  border-color: #2d2d9f !important;
}
