@import "../../../styles/variables";

.PreviewProductContainer {
  h3 {
    font-weight: 700;
  }
  .navTabs {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 2px solid $primary;
    li {
      flex: 1; /* Distribute remaining space equally among li elements */
      button {
        color: black;
        font-size: 17px;
        font-weight: 700;
        padding: 10px;
        width: 100%;
        &:hover {
          background-color: $primary;
          color: white;
        }
      }
    }
  }
  .addToCart {
    width: 100%;
    p {
      font-weight: 800;
    }
    margin-top: 1rem;
  }
}
.card {
  overflow:hidden;
  .card_title {
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 3rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
  .card_price {
    color: $secondary;
    font-weight: 800;
    font-size: 1rem;
    margin-right: 0.8rem;
  }

  .card_price_origin {
    font-weight: light;
    color: gray;
    font-size: 1rem;
    text-decoration: line-through;
  }
  .card_discount {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #c8102e;
    padding: 6px 8px;
    border-radius: 23px 23px 23px 0px;
    font-weight: 800;
    font-size: 14px;
    z-index: 3;
    color: #fff;
  }
  .zoomEffect {
    object-fit: fill;
    width: 100%;
    height: 200px;
  }
}

.card:hover .zoomEffect {
  transition: transform 0.5s;
  transform: scale(1.05);
}
.card:hover .card_title {
  color: $primary;
  text-decoration: underline;
}

.stickyRight {
  position: sticky;
  z-index: 999;
  top: 70px;
  .orderCart {
    background: #fff;
    border-radius: 4px;
    .cartMobile {
      cursor: pointer;
      display: none;
      background: $primary;
      padding: 12px;
      span {
        color: white;
        font-weight: 500;
      }
    }
    .accordion_body {
      padding: 0;
    }
    .OrderCartItemHeader {
      padding: 20px;
      overflow-y: auto;
      min-height: calc(100vh - 625px);
      max-height: calc(100vh - 300px);
      .OrderCartItem {
        display: flex;
        flex-wrap: wrap;
        .itemModifiers {
          .modifiers_item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: 13.5px;
            padding-left: 1rem;
            div {
              padding-left: 4px;
            }
          }
        }
        .itemRow {
          float: left;
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 1rem;
          border-bottom: solid 1px #ebebeb;
          .itemThumb {
            float: left;
            max-width: 100%;
            img {
              height: 70px;
              width: 70px;
              margin: 0;
            }
          }
          .itemName {
            width: 100%;
            text-align: left;
            padding-left: 85px;
            padding-bottom: 5px;
            .cartOrderItemName {
              text-decoration: none;
              color: #000;
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 0.5rem;
            }
          }
          .itemPrice {
            margin-left: 1rem;
            margin-right: 1rem;
            float: left;
            color: $danger;
            font-weight: 800;
          }
          .itemDecrement {
            cursor: pointer;
            background: transparent;
            float: left;
            border: 1px solid;
            color: $primary;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            padding: 0;
          }
          .itemQuantity {
            color: #222;
            float: left;
            font-weight: 500;
            margin-left: 8px;
            margin-right: 8px;
            background-color: #fff;
            text-align: center;
          }
          .itemIncrement {
            cursor: pointer;
            background: transparent;
            border: 1px solid;
            color: $primary;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            box-shadow: none;
            padding: 0;
          }

          .itemRemove {
            cursor: pointer;
            float: right;
            color: $secondary;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
    .OrderCartItemBottom {
      position: relative;
      padding: 20px 20px 15px;
      background: #f0f0f0;
      .subTotal,
      .TotalBill {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .subTotal {
        margin-bottom: 5px;
        span {
          font-weight: bold;
        }
      }
      .TotalBill {
        span {
          font-size: 1.3rem;
          color: $secondary;
          font-weight: bold;
        }
      }
    }
    .OrderCartItemBottom::before {
      content: "";
      display: inline-block;
      height: 10px;
      margin: 0 auto;
      position: absolute;
      top: -5px;
      left: 0;
      right: 0;
      text-align: center;
      width: 10px;
      box-shadow: 0 0 0 6px #ffffff;
      background: $primary;
      border-radius: 1px;
      transform: rotate(-45deg);
    }
  }
}
.nav_link {
  margin-bottom: calc(-1 * 1px);
  background: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
  &:hover {
    transition: all 100ms ease-in-out;
  }
  &.nav_link_active {
    background-color: $primary;
    color: white !important;
  }
}
.ViewMoreProduct {
  padding: 0.7rem 1.5rem;
  background-color: $btn-gray;
  text-decoration: none;
  color: rgb(48, 48, 48);
  border-radius: 6px;
  &:hover {
    background-color: $primary;
    color: white;
  }
}
.CheckoutButtonLink {
  text-decoration: none;
  color: white;
  .CheckoutButton {
    margin-top: 1rem;
    width: 100%;
    p {
      font-size: 1rem;
      font-weight: 800;
    }
  }
}

.mobile-bottom-bar {
  position: relative;
  min-height: 100vh;
}

.bottom-bar {
  padding: 10px;
}

@media screen and (max-width: 994px) {
  .nav_link {
    font-size: 15px !important;
  }
  .cartMobile {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .card {
    .card_title {
      font-size: 0.8rem !important;
      height: 40px;
      margin-bottom: 2rem;
    }
    .card_price {
      font-size: 0.9rem;
    }
    .card_price_origin {
      font-size: 0.9rem;
    }
    .zoomEffect {
      height: 140px;
    }
  }
  .card_body {
    padding: 8px !important;
  }
}

@media screen and (max-width: 544px) {
  .PreviewProductContainer {
    padding-left: 10px !important;
    padding-right: 10px !important;
    .nav_link {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .card_title {
      font-size: .8rem !important;
      text-align: left;
    }
  }
}
