@import "../../styles/variables";
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $btn-padding-y $btn-padding-x;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid $primary;
  transition: all 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  color: white;
  p {
    font-size: 14px;
    font-weight: 400;
    margin-left: 2px;
  }
}

.start_icon,
.end_icon {
  padding-bottom: 3px;
  font-size: 14px;
}

.start_icon {
  margin-right: 2px;
}
.end_icon {
  margin-left: 2px;
}

.hasShadow {
  box-shadow: $box-shadow;
}

// Primary Button Colors
.primary {
  background-color: $primary;
}

.secondary {
  background-color: $secondary;
}

.success {
  background-color: $success;
}


.textLoading {
  padding-left: 5px;
}
.inactive {
  opacity: 0.5;
  pointer-events: none;
}
