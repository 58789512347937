@import "../../../styles/variables";
.StyleBreadcrumbPadding {
  padding: 20px 60px 10px 30px !important;
}
.linkPath {
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  color: black;
  &:hover {
    color: $primary;
  }
}

.active {
  color: $primary;
}

@media screen and (max-width: 994px) {
  .StyleBreadcrumbPadding {
    padding: 10px !important;
  }
}