@import "../../../styles/variables";
.HeroContainer {
  background-color: #f5f4ef;
  margin-top: 10px;
  .HeroInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    .left {
      text-align: center;
      position: relative;
      h2 {
        color: $primary;
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      .HeroLeftParagraph {
        text-align: left;
        font-size: 19px;
        width: 600px;
        margin-bottom: 0.5rem;
        padding: 0 2.5rem;
      }
      h5 {
        font-size: 16px;
        font-weight: 400;
        color: $primary;
        position: relative;
        padding: 0 5px 5px 5px;
        display: inline-block;
        margin-bottom: 10px;
      }
      h5::before {
        content: "";
        position: absolute;
        border-color: $primary;
        border-style: solid;
        border-width: 0 0 0.15em 0;
        height: 1em;
        top: 1.7em;
        left: -30px;
        margin-top: -1em;
        transform: rotate(180deg);
        width: 1.5em;
      }

      h5::after {
        content: "";
        position: absolute;
        border-color: $primary;
        border-style: solid;
        border-width: 0 0 0.15em 0;
        height: 1em;
        top: 1.7em;
        right: -30px;
        margin-top: -1em;
        transform: rotate(180deg);
        width: 1.5em;
      }
      .readMoreBox {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          p {
            font-size: 17px;
          }
          margin-top: 20px;
          padding: 0.5rem 2.2rem;
        }
      }
    }
    .right {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 994px) {
  .HeroInner {
    display: block !important;
    .left {
      padding-bottom: 2rem;
      .HeroLeftParagraph {
        width: 100% !important;
      }
    }
  }
}