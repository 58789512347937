body {
  margin: 0;
  font-family: 'Mulish', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  padding: 0;
  box-sizing: border-box;
}

a:hover {
  color: #333f48 ;
}
i,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0;
  margin: 0;
}
textarea:hover,
input:hover,
textarea:active,
input:active,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active,
input:focus,
select:focus,
textarea:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}
