@import "../../../styles/variables";

.Footer {
  background-color: $bg-footer;
  padding: 40px 40px 70px 40px;
  .FooterItem {
    h5 {
      color: white;
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 1.4rem;
    }
    li {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      p {
        display: flex;
        align-items: center;
        font-size: 17px;
        font-weight: 550;
        color: white;
        span {
          padding-right: 0.5rem;
        }
        a {
          text-decoration: none;
          color: white;
          &:hover {
            color: $primary;
            text-decoration: underline;
          }
        }
        span {
          font-weight: 800;
        }
      }
    }
  }
  .footer_col_4 {
    h5 {
      color: white;
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 1.4rem;
    }
    input {
      color: #fff;
      border: 2px solid #282828;
      background-color: transparent;
      width: 100%;
      border-radius: 4px;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding: .5rem;
    }
    .news_register {
      width: 100%;
      transition: all 100ms ease-in-out;
      p {
        font-weight: 800;
        font-size: 16px;
      }
      &:hover {
        background: $success;
        border: 1px solid $success;
      }
    }
  }
}
.copyright {
  background-color: $primary;
  padding: 15px 0;
  font-size: 14px;
  color: #fff;
  padding-top: 30px;
  position: relative;
  .section_1 {
    position: absolute;
    text-align: center;
    top: -24px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    a {
      display: inline-block;
      height: 45px;
      width: 45px;
      background-color: $primary;
      margin-right: 0.5rem;
      border: 2px solid #fff;
      text-align: center;
      line-height: 45px;
      border-radius: 50%;
      position: relative;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: $primary;
      }
      svg {
        height: 22px;
        width: 22px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
      }
    }
  }
  .section_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin: 0 0.5rem;
    }
  }
  .section_3 {
    text-align: center;
    img {
      max-height: 37px;
      max-width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 994px) {
  .Footer {
    padding: 40px 10px 70px 10px !important;
  }
}