@import "../../../styles/variables";
.ProductSuggestion {
  h2 {
    text-align: left;
    margin: 2rem 0 !important;
    font-size: 2.5rem !important;
  }
}

.card {
  overflow: hidden;
  .card_title {
    display: flex;
    align-items: center;
    padding: 0;
    text-decoration: none;
    color: black;
    justify-content: left;
    height: 35px;
    font-weight: 700;
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
  .card_price {
    color: $secondary;
    font-weight: 800;
    font-size: 1rem;
    margin-right: 0.8rem;
  }

  .card_price_origin {
    font-weight: light;
    color: gray;
    font-size: 1rem;
    text-decoration: line-through;
  }
  .card_discount {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #c8102e;
    padding: 6px 8px;
    border-radius: 23px 23px 23px 0px;
    font-weight: 550;
    font-size: 14px;
    z-index: 3;
    color: #fff;
  }
  .zoomEffect {
    object-fit: fill;
    width: 100%;
    height: 200px;
  }
}
.addToCart {
    width: 100%;
    p {
      font-weight: bold;
    }
    margin-top: 1rem;
  }


  @media screen and (max-width: 554px) {
    .ProductSuggestion {
      h2 {
        font-size: 1.6rem !important;
      }
    }
    .card {
      .card_title {
        font-size: .8rem !important;
      }
      .card_price,
      .card_price_origin {
        font-size: .9rem !important;
      }
    }
  }