@import "../../../styles/variables";
.CartContainer {
  padding: 10px 60px !important;

  .left {
    padding-left: 0.5rem;
    .CartTitle {
      display: flex;
      margin-bottom: 1rem;
      h5 {
        margin-right: 5px;
      }
    }
    .itemRow {
      float: left;
      width: 100%;
      padding-bottom: 10px;
      margin-bottom: 1rem;
      border-bottom: solid 1px #ebebeb;
      .itemThumb {
        float: left;
        height: 70px;
        width: 70px;
        img {
          max-width: 100%;
          margin: 0;
        }
      }
      .itemName {
        width: 100%;
        text-align: left;
        padding-left: 85px;
        padding-bottom: 5px;
        .cartOrderItemName {
          text-decoration: none;
          color: #000;
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 0.5rem;
          &:hover {
            color: $primary;
            text-decoration: underline;
          }
        }
      }
      .itemPrice {
        margin-left: 1rem;
        margin-right: 1rem;
        float: left;
        color: $secondary;
        font-weight: 700;
      }
      .itemDecrement {
        cursor: pointer;
        background: transparent;
        float: left;
        border: 1px solid;
        color: $primary;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        padding: 0;
      }
      .itemQuantity {
        color: #222;
        float: left;
        font-weight: 500;
        margin-left: 8px;
        margin-right: 8px;
        background-color: #fff;
        text-align: center;
      }
      .itemIncrement {
        cursor: pointer;
        background: transparent;
        border: 1px solid;
        color: $primary;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        box-shadow: none;
        padding: 0;
      }

      .itemRemove {
        cursor: pointer;
        float: right;
        color: $secondary;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .continueShoppingLink {
      color: $black;
    }
    .OrderCartItemBottom {
      margin-top: 5px;
      .subTotal,
      .TotalBill {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .subTotal {
        margin-bottom: 5px;
        span {
          font-weight: bold;
        }
      }
      .TotalBill {
        span {
          font-size: 1.3rem;
          color: $secondary;
          font-weight: bold;
        }
      }
      .CheckoutButtonLink {
        text-decoration: none;
        .CheckoutButton {
          margin-top: 1rem;
          width: 100%;
          p {
            font-weight: bold;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .emptyCartNotification {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    h5 {
      font-weight: 300;
    }
  }
  .right {
    padding-right: 1rem;
  }
}
